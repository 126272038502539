import { Typography } from "@mui/material";
import React from "react";

const HelperCard = ({ card }) => {
  return (

    <div className="">
 
  <div className="card helpersCol">
 
    <div className="card-body d-flex flex-center flex-column py-9 px-5">
   
      <div className="symbol symbol-65px symbol-circle mb-5">
      <img src={`images/users/${card.image}`} alt={`Helpers ${card.id}`} />
        <div className="bg-success position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-body h-15px w-15px ms-n3 mt-n3"></div>
        
      </div>
      <div className="wishlistIcon">
         <img src="wishlist-icon.svg" alt="Wishlist Icon" />
       </div>

      <a href="#" className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0" style={{textDecoration: 'none'}}>
      <Typography variant="h5">{card.title}</Typography>
      </a>

      <div className="fw-semibold text-gray-500 mb-6 locationDate">
         <div className="location">{card.location}

         </div>
        <div className="date">{card.date}</div>
        
        </div>

      <button className="btn btn-sm btn-light-primary btn-flex btn-center" >
        <i className="ki-duotone ki-check following fs-3"></i>
        {/* <i className="ki-duotone ki-plus follow fs-3 d-none"></i> */}
  
        <span className="indicator-label">{card.availability}</span>
    
        {/* <span className="indicator-progress">
          Please wait...
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span> */}
      
      </button>

    </div>
  
  </div>

</div>

  );
};

export default HelperCard;
