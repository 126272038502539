import React from 'react';
import PropTypes from 'prop-types';

const Expectations = ({ expectation }) => {
    const jobPreferences = expectation?.registrationStep4Data?.jobPreferences;
    const preferredJobs = jobPreferences?.preferredJobs || [];
    return (
        <>
            <div className="education">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#six"
                            aria-expanded="true"
                            aria-controls="collapseThree"
                        >
                            My Expectations
                        </button>
                    </h2>
                    <div
                        id="six"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="salary pb-2 mb-2">
                                            <h5 className="pass-1">Type of employment:</h5>
                                            <span>{jobPreferences?.jobType} </span>
                                        </div>
                                        <div className="salary pb-2 mb-2 mt-3">
                                            <h5 className="pass-2">Preferred Jobs:</h5>
                                            <ul className="skill-h skill-not">
                                            </ul>
                                            <ul className="skill-h skill-not">
                                                {preferredJobs.map((lang, index) => (
                                                    <li key={index}><a href="javascript:void(0)">{lang}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="salary pb-2 mb-2 mt-3">
                                            <h5 className="pass-3">Preferred working Location:</h5>
                                            <span> {jobPreferences?.preferredLocation}</span>
                                        </div>
                                        <div className="salary pb-2 mb-2 mt-3">
                                            <h5 className="pass-4">Salary:</h5>
                                            <span> {jobPreferences?.expectedSalary}</span>
                                        </div>
                                        <div className="salary pb-2 mb-2 mt-3">
                                            <h5 className="pass-5">Accommodation:</h5>
                                            <span> {jobPreferences?.livingArrangement}</span>
                                        </div>
                                        <div className="salary pb-3">
                                            <h5 className="pass-6">Day Off:</h5>
                                            <span> {jobPreferences?.preferredDayOff}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Expectations;
