import React from 'react';

const FamilyMembersInfo = ({ familyMembers }) => {




    // Function to format ages
    const formatAges = () => {
        // Check if familyMembers exists and is an array
        if (Array.isArray(familyMembers) && familyMembers.length > 0) {
            return familyMembers
                .map(member => `${member.age}yr`) // Map each age
                .join(', '); // Join them with commas
        }
        return '';
    };

    // Function to count genders
    const countGenders = () => {
        if (Array.isArray(familyMembers) && familyMembers.length > 0) {
            const genderCounts = familyMembers.reduce((acc, member) => {
                acc[member.gender] = (acc[member.gender] || 0) + 1; // Count each gender
                return acc;
            }, {});
            return Object.entries(genderCounts)
                .map(([gender, count]) => `${count} ${gender}`) // Format as "count gender"
                .join(', '); // Join with commas
        }
        return ''; // Fallback
    };

    return (
        <>
            <p>
                <b>Number of Family Members: </b> {familyMembers?.length || 0}
            </p>
            <p>
                <b>Age: </b> {formatAges()}
            </p>
            <p>
                <b>Gender: </b> {countGenders()}
            </p>
        </>
    );
};

export default FamilyMembersInfo;
