import React from 'react';

const DutiesInfo = ({ duties }) => {
    // Function to format duties
    const formatDuties = () => {
        if (Array.isArray(duties) && duties.length > 0) {
            return duties.join(', '); // Join the duties with a comma and space
        }
        return 'No duties available'; // Fallback if duties array is empty or not an array
    };

    return (
        <>
            <p>
                <b>Duties:</b> {formatDuties()}
            </p>

        </>
    );
};

export default DutiesInfo;
