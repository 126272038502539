import React from 'react';

const SkillComponent = ({ para, skillType, skill }) => {
    return (
        <div className="row">
            <div className="col-12 pb-4 line">
                <h5 className={`${para} para`}>{skillType}:</h5>
                <ul className="skill-h">
                    {skill && skill.map((lang, index) => (
                        <li key={index}><a href="javascript:void(0)">{lang}</a></li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default SkillComponent;
