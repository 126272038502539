import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { toastMessage } from "../../Utils/toastMessages";
import { useTranslation } from "react-i18next";

// Import Custom Components
import PageLoader from "../../Components/Common/Loader/PageLoader";
import HelperCard from "../../Components/HelperProfile/ProfileView/HelperCard";
import HelperProfile from "../../Components/HelperProfile/ProfileView/HelperProfile";
import ProfessionalInfo from "../../Components/HelperProfile/ProfileView/ProfessionalInfo";
import SkillsDuties from "../../Components/HelperProfile/ProfileView/SkillsDuties";
import AboutMe from "../../Components/HelperProfile/ProfileView/AboutMe";
import WorkExperience from "../../Components/HelperProfile/ProfileView/WorkExperience";
import Education from "../../Components/HelperProfile/ProfileView/Education";
import Expectations from "../../Components/HelperProfile/ProfileView/Expectations";
import GeneralInformation from "../../Components/HelperProfile/ProfileView/GeneralInformation";
import Certificates from "../../Components/HelperProfile/ProfileView/Certificates";
import YouTubeVideoLike from "../../Components/HelperProfile/ProfileView/YouTubeVideoLike";
import ReportButton from "../../Components/HelperProfile/ProfileView/ReportButton";


import { getProfileData } from "../../Services/ProfileServices/ProfileService";


// Main Functional Component
const ViewFullProfile = () => {
    const { id } = useParams();

    const { t } = useTranslation();
    const [isFavourite, setIsFavourite] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState([]);
    const [helperDetails, setHelperDetails] = useState(null);
    const [profileDetail, setProfileDetail] = useState();


    useEffect(() => {
        const handleGetHelperPublicProfile = async () => {
            setLoader(true);
            getProfileData(id)
                .then((res) => {
                    setHelperDetails(res?.data);
                    setProfileDetail(res?.data);
                    setIsFavourite(res?.data?.isFavorite);
                })
                .catch((err) => {
                    if (err?.response?.data?.message) {
                        toastMessage(err.response.data.message);
                    } else {
                        toastMessage(t("failure_message"));
                    }
                })
                .finally((res) => setLoader(false));
        };
        handleGetHelperPublicProfile();
    }, [id]);

    return (
        <>
            {loader && <PageLoader />}
            <section className="help">
                <div className="container help">
                    <div className="row aim">
                        <Grid item lg={9}>
                            <HelperProfile helperDetails={profileDetail || {}} />
                            <div className="accordion" id="accordionExample">
                                <ProfessionalInfo professionalDetails={profileDetail || {}} />
                                <SkillsDuties profileDetail={profileDetail} />

                                <AboutMe title='About Me' aboutText={profileDetail?.registrationStep4Data?.jobPreferences?.introduceYourself?.specialAboutYou} />

                                <WorkExperience workExperience={profileDetail || {}} />

                                <Education educationDetails={profileDetail || {}} />

                                <Expectations expectation={profileDetail || {}} />

                                <GeneralInformation profileDetail={profileDetail || {}} />

                                <Certificates profileDetail={profileDetail || {}} />

                                <YouTubeVideoLike profileDetail={profileDetail || {}} />
                            </div>
                        </Grid>
                        <Grid item lg={3}>
                            <HelperCard />
                        </Grid>
                    </div>
                </div>
            </section >
        </>
    );
};

// Export Component
export default ViewFullProfile;

