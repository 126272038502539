import React from 'react';
import PropTypes from 'prop-types';

const ProfessionalInfo = ({ professionalDetails }) => {



    const employerNationality = professionalDetails?.registrationStep3Data?.[0]?.employerNationality || 'Unknown';
    const nationality = Array.isArray(employerNationality) ? employerNationality.join(',') : '';

    const maritalStatus = professionalDetails?.registrationStep2Data?.aboutYou?.maritalStatus || '';
    const preferredLocation = professionalDetails?.registrationStep4Data?.jobPreferences?.preferredLocation || '';
    const noOfBrothers = professionalDetails?.registrationStep2Data?.aboutYourFamily?.brothers || '';
    const noOfSisters = professionalDetails?.registrationStep2Data?.aboutYourFamily?.sisters || '';

    const dateOfBirth = professionalDetails?.registrationStep2Data?.aboutYou?.dob || '';
    const religion = professionalDetails?.registrationStep2Data?.aboutYou?.religion || '';
    const familyOrder = professionalDetails?.registrationStep2Data?.aboutYourFamily?.familyOrder || '';
    const passportOrHKID = professionalDetails?.registrationStep2Data?.aboutYou?.passportOrHKID || '';
    const experience = professionalDetails?.registrationStep3Data?.[0]?.experience || '';

    const height = professionalDetails?.registrationStep2Data?.aboutYou?.physicalAttributes?.height || '';
    const weight = professionalDetails?.registrationStep2Data?.aboutYou?.physicalAttributes?.weight || '';
    const Whatsapp = professionalDetails?.registrationStep2Data?.aboutYou?.whatsapp?.number || '';
    const currentEmploymentStatus = professionalDetails?.registrationStep2Data?.aboutYou?.currentEmploymentStatus || '';



    return (
        <>
            <div className="professional-info mt-4">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                        >
                            Professional Info
                        </button>
                    </h2>

                    <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-12">
                                        <div className="d-flex colnew">
                                            <h5 className="par1">Nationality</h5>
                                            <span className="hong"> {nationality}</span>

                                        </div>
                                        <div className="d-flex colnew">
                                            <h5 className="par2">Marital Status</h5>
                                            <span className="hong"> {maritalStatus}</span>
                                        </div>
                                        <div className="d-flex colnew">
                                            <h5 className="par3">Date of Birth</h5>
                                            <span className="hong"> {dateOfBirth}</span>
                                        </div>
                                        <div className="d-flex colnew">
                                            <h5 className="par4">Religion</h5>
                                            <span className="hong">{religion}</span>
                                        </div>
                                        <div className="d-flex colnew">
                                            <h5 className="par5">Passport No</h5>
                                            <span className="hong">{passportOrHKID}</span>
                                        </div>
                                        <div className="d-flex colnew">
                                            <h5 className="par7">Whatsapp Number</h5>
                                            <span className="hong"> {Whatsapp}</span>
                                        </div>
                                        <div className="d-flex colnew">
                                            <h5 className="par6">Experience</h5>
                                            <span className="hong">{experience}</span>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12 col-12">
                                        <div className="d-flex colnew">
                                            <h5 className="par8">Present Location</h5>
                                            <span className="hong">{preferredLocation}</span>
                                        </div>
                                        <div className="d-flex colnew">
                                            <h5 className="par9">No of Brother</h5>
                                            <span className="hong">{noOfBrothers}</span>
                                        </div>
                                        <div className="d-flex colnew">
                                            <h5 className="par10">No of Sisters</h5>
                                            <span className="hong">{noOfSisters}</span>
                                        </div>
                                        <div className="d-flex colnew">
                                            <h5 className="par11">I am no.</h5>
                                            <span className="hong"> {familyOrder}</span>
                                        </div>
                                        <div className="d-flex colnew">
                                            <h5 className="par12">Weight</h5>
                                            <span className="hong"> {weight} Kg</span>
                                        </div>
                                        <div className="d-flex colnew">
                                            <h5 className="par13">Height</h5>
                                            <span className="hong">{height} cm</span>
                                        </div>
                                        <div className="d-flex colnew hns">
                                            <h5 className="par14">Current Employment</h5>
                                            <span className="hong">  {currentEmploymentStatus}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};



export default ProfessionalInfo;
