import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const ViewJobsButtonComponent = ({ phoneNumber }) => {
    const selectedRole = localStorage.getItem('selectedRole');

    const navigate = useNavigate();  // Initialize navigate from react-router-dom
    const { t } = useTranslation();  // Initialize t for translations from react-i18next


    return (
        <div className="help-1-btn d-flex flex-column justify-content-around align-items-center">
            <span className="cont-per">Phone Number</span>
            <h5 className="cont-no"> {phoneNumber}  </h5>
            {/* Contact Button */}

            {selectedRole === 'helper' ? (
                <Button
                    className="arrowButton"
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/helper/edit-profile")}
                >
                    {t("edit_profile")}
                </Button>
            ) : (
                <Button
                    className="arrowButton"
                    variant="contained"
                    color="primary"
                // You can add an onClick here if needed
                >
                    {t("contact")}
                </Button>
            )}

        </div>
    );
};

export default ViewJobsButtonComponent;
