import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getAllUsersList } from "../../../Services/JobsServices/JobServices";

const HelperCard = () => {
    const navigate = useNavigate();

    const [loader, setLoader] = useState(false);
    const [applicants, setApplicants] = useState([]);
    const [error, setError] = useState([]);


    useEffect(() => {
        const fetchApplicants = async () => {
            try {
                const query = `page=1&limit=10`;
                const res = await getAllUsersList(query);
                const response = res?.data;
                if (response) {
                    setApplicants(response.profiles || []);
                } else {
                    throw new Error("No response data found");
                }
            } catch (err) {
                console.error("Error fetching applicants:", err);
                setError("Failed to load applicants. Please try again.");
            }
        };

        fetchApplicants();
    }, []);


    const handleRedirect = (userId) => {
        navigate(`/applicant-profile-view/${userId}`);
    };

    return (
        <div className="row flex-lg-column">
            {applicants.map((helper, index) => (
                <div key={index} className="col-lg-12 col-md-6 col-12 d-flex mt-4">

                    <div className="help-1-o d-flex"
                        onClick={() => {
                            handleRedirect(helper.userId);
                        }}
                        style={{ cursor: 'pointer' }}

                    >
                        <div className="help-o">
                            {/* Fallback for missing profile picture */}
                            <img
                                src={helper?.profilePicURL || "/demo-user.png"}
                                alt="userimg"
                                onError={(e) => {
                                    e.target.onerror = null; // Prevents infinite loop in case fallback image also fails
                                    e.target.src = "/demo-user.png"; // Set fallback image
                                }}
                            />
                            <a href="#" className="help-o-btn">{helper.jobType || "Unknown Job"}</a>
                        </div>
                        <div className="help-1-o-c">
                            {/* Fallback for missing full name */}
                            <h3>{helper?.aboutYou?.fullName || "Anonymous"}</h3>
                            {/* <p>HelperDocs - Finished Contract</p> */}

                            {/* Fallback for missing location or experience */}
                            <div className="help-1-o-c-1 d-flex align-items-center">
                                <i className="fa-solid fa-location-dot"></i>
                                <span>
                                    {helper?.aboutYou?.location || ""}
                                </span>

                            </div>
                            <div className="help-1-o-c-1 d-flex align-items-center">
                                <i className="fa-solid fa-briefcase"></i>
                                <span>
                                    {helper?.workExperiences?.[0]?.experience || ""}
                                </span>
                            </div>

                            {/* Fallback for missing work period end date */}
                            <div className="help-1-o-c-1 d-flex align-items-center">
                                <i className="fa-regular fa-calendar"></i>
                                <span>
                                    From {helper?.workExperiences?.[0]?.period?.end || ""}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ))
            }
        </div >

    );
};

export default HelperCard;