import React from "react";
import { Typography } from "@mui/material";

const ContentList = ({ items }) => {
    return (
        <Typography variant="body1" component="div">
            <ul style={{ listStyle: "none", padding: 0 }}>
                {items.map((item) => (
                    <li key={item.id} style={{ marginBottom: "20px" }}>
                        <Typography variant="h5">{item.title}</Typography>
                        <Typography variant="body2">{item.content}</Typography>
                    </li>
                ))}
            </ul>
        </Typography>
    );
};

export default ContentList;