import React from 'react';
import PropTypes from 'prop-types';

const Education = ({ educationDetails }) => {

    return (
        <>

            <div className="education">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#five"
                            aria-expanded="true"
                            aria-controls="collapseThree"
                        >
                            Education
                        </button>
                    </h2>
                    <div
                        id="five"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="house pb-4 mt-3">
                                            <h5 className="acer">{educationDetails?.registrationStep2Data?.education?.level}</h5>
                                            <div className="d-flex align-items-center move">
                                                <i className="fa-regular fa-calendar"></i>
                                                <span>{educationDetails?.registrationStep2Data?.education?.start} - {educationDetails?.registrationStep2Data?.education?.end}</span>
                                            </div>
                                            <div className="add-h">
                                                <span className="add">
                                                    {educationDetails?.registrationStep2Data?.education?.major}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};



export default Education;
