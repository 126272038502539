import React from "react";

import SkillComponent from "./SkillComponent";

const mainSkills = ['Newborn (0-1)', 'Toddler (1-3)', 'Child (4-12)', 'Teen (13-17)', 'Elderly (>70)', 'Special Care', 'Pet'];
const maincookingSkills = ["Arabic", "Chinese", "Indian", "Thai", "Western", "Vegetarian", "Baking", "Dessert"];
const otherSkills = ['Car Washing', 'Cleaning', 'Marketing', 'Gardening'];


const SkillsDuties = ({ profileDetail }) => {


    const otherLanguages = profileDetail?.registrationStep2Data?.education?.otherLanguages || [];
    const languagess = [profileDetail?.registrationStep2Data?.education?.languages?.native];

    const otherLanguageNames = otherLanguages.map(lang => lang.language);
    const allLanguages = [...languagess, ...otherLanguageNames];

    const skills = profileDetail?.registrationStep2Data?.aboutYou?.skills || [];

    const newSkills = mainSkills.filter(skill => skills.includes(skill));
    const newCookingSkills = maincookingSkills.filter(skill => skills.includes(skill));
    const newOtherSkills = otherSkills.filter(skill => skills.includes(skill));


    const data = profileDetail?.registrationStep2Data?.aboutYou?.physicalAttributes || [];

    const personalityTraits = [`height: ${data.height}`, `weight: ${data.weight}`];

    return (
        <>
            <div className="skill">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="true"
                            aria-controls="collapseTwo"
                        >
                            Skills / Duties
                        </button>
                    </h2>

                    <div
                        id="collapseTwo"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <div className="row">
                                <SkillComponent
                                    para='para5'
                                    skillType="Language"
                                    skill={allLanguages}
                                />
                                <SkillComponent
                                    para='para1'
                                    skillType="Care Skills"
                                    skill={newSkills}
                                />
                                <SkillComponent
                                    para='para2'
                                    skillType="Cooking Skills"
                                    skill={newCookingSkills}
                                />
                                <SkillComponent
                                    para='para3'
                                    skillType="HouseholdChore Skills"
                                    skill={newOtherSkills}
                                />
                                <SkillComponent
                                    para='para4'
                                    skillType="Personality"
                                    skill={personalityTraits}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default SkillsDuties;
