import React from 'react';
import PropTypes from 'prop-types';

const AboutMe = ({ title, aboutText }) => {
    return (
        <>
            <div className="about-me">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="true"
                            aria-controls="collapseThree"
                        >
                            {title}
                        </button>
                    </h2>
                    <div
                        id="collapseThree"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-12">
                                        <p>{aboutText}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

AboutMe.propTypes = {
    aboutText: PropTypes.string.isRequired,
};

export default AboutMe;
