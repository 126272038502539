import React from 'react';
import PropTypes from 'prop-types';

const Certificates = ({ profileDetail }) => {
    // Extracting licenses and certificates from profile details
    const licensesAndCertificates = profileDetail?.registrationStep2Data?.education?.licensesAndCertificates;

    return (
        <>


            <div className="Expectations">
                <h2 className="accordion-header">
                    <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#eight"
                        aria-expanded="true"
                        aria-controls="flush-collapseThree"
                    >
                        Certificates
                    </button>
                </h2>
                <div
                    id="eight"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                >
                    <div className="row">
                        {licensesAndCertificates?.firstAid ? (
                            <div className="col-lg-3 col-md-6 col-12 d-flex justify-content-center">
                                <a href={licensesAndCertificates.firstAid} download target="_blank" >
                                    <img
                                        className="certi"
                                        src="/award.svg"
                                        alt="Download First Aid Certificate"
                                        style={{ cursor: 'pointer', width: '100%', height: 'auto' }} // Ensure the image is responsive
                                    />
                                    <p className="text-center" style={{ marginTop: ' -70px' }}>First Aid Certificate</p>
                                </a>
                            </div>
                        ) : (
                            <></>
                        )}

                        {licensesAndCertificates?.elderlyCaregiving ? (
                            <div className="col-lg-3 col-md-6 col-12 d-flex justify-content-center">
                                <a href={licensesAndCertificates.elderlyCaregiving} download target="_blank" >
                                    <img
                                        className="certi"
                                        src="/award.svg"
                                        alt="Download Elderly Caregiving Certificate"
                                        style={{ cursor: 'pointer', width: '100%', height: 'auto' }} // Ensure the image is responsive
                                    />
                                    <p className="text-center" style={{ marginTop: ' -70px' }}>Elderly Caregiving Certificate</p>
                                </a>
                            </div>
                        ) : (
                            <></>
                        )}


                        {licensesAndCertificates?.newBornCaregiving ? (
                            <div className="col-lg-3 col-md-6 col-12 d-flex justify-content-center">
                                <a href={licensesAndCertificates.newBornCaregiving} download target="_blank" >
                                    <img
                                        className="certi"
                                        src="/award.svg"
                                        alt="Download Newborn Caregiving Certificate"
                                        style={{ cursor: 'pointer', width: '100%', height: 'auto' }} // Ensure the image is responsive
                                    />
                                    <p className="text-center" style={{ marginTop: ' -70px' }}>Newborn Caregiving Certificate</p>
                                </a>
                            </div>
                        ) : (
                            <></>
                        )}

                        {licensesAndCertificates?.validDrivingLicenseintheCountryyouwanttowork ? (
                            <div className="col-lg-3 col-md-6 col-12 d-flex justify-content-center">
                                <a href={licensesAndCertificates.validDrivingLicenseintheCountryyouwanttowork} download target="_blank" >
                                    <img
                                        className="certi"
                                        src="/award.svg"
                                        alt="Download Valid Driving License"
                                        style={{ cursor: 'pointer', width: '100%', height: 'auto' }} // Ensure the image is responsive
                                    />
                                    <p className="text-center" style={{ marginTop: ' -70px' }}>Valid Driving License</p>
                                </a>
                            </div>
                        ) : (
                            <></>
                        )}

                        {licensesAndCertificates?.driving ? (
                            <div className="col-lg-3 col-md-6 col-12 d-flex justify-content-center">
                                <a href={licensesAndCertificates.driving} download target="_blank" >
                                    <img
                                        className="certi"
                                        src="/award.svg"
                                        alt="Download Valid Driving License"
                                        style={{ cursor: 'pointer', width: '100%', height: 'auto' }} // Ensure the image is responsive
                                    />
                                    <p className="text-center" style={{ marginTop: ' -70px' }}>Driving License</p>
                                </a>
                            </div>
                        ) : (
                            <></>
                        )}



                    </div>
                </div>
            </div >

        </>
    );
};

// Prop types validation
Certificates.propTypes = {
    profileDetail: PropTypes.shape({
        registrationStep2Data: PropTypes.shape({
            education: PropTypes.shape({
                licensesAndCertificates: PropTypes.shape({
                    firstAid: PropTypes.string,
                    elderlyCaregiving: PropTypes.string,
                    newBornCaregiving: PropTypes.string,
                    validDrivingLicenseintheCountryyouwanttowork: PropTypes.string,
                }),
            }),
        }),
    }),
};

export default Certificates;
