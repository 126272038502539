import React from 'react';

const ReportButton = () => {
    return (
        <div className="end-btn d-flex justify-content-end">
            <a href="#" className="report-link">Report this Candidate</a>
        </div>
    );
};

export default ReportButton;
