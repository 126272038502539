import React from 'react';

const YouTubeVideoLike = ({ profileDetail }) => {
    const introVideoLink = profileDetail?.registrationStep6Data?.introVideoLink;

    // Convert URL to embed format if needed
    const getEmbedUrl = (url) => {
        if (!url) return null;
        const videoIdMatch = url.match(/(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&]+)/);
        const videoId = videoIdMatch ? videoIdMatch[1] : null;
        return videoId ? `https://www.youtube.com/embed/${videoId}` : url;
    };

    const embedUrl = getEmbedUrl(introVideoLink);



    return (
        <div className="Expectations">
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#nine"
                        aria-expanded="true"
                        aria-controls="flush-collapseThree"
                    >
                        Self Intro Video
                    </button>
                </h2>
                <div
                    id="nine"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionFlushExample"
                >
                    <div className="row gy-4">
                        <div className="col-lg-12 col-md-12">
                            <div className="otder-2 d-flex justify-content-center align-items-center">
                                {embedUrl ? (
                                    <iframe
                                        width="460"
                                        height="300"
                                        src={embedUrl}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                    ></iframe>
                                ) : (
                                    <p>No video available</p>
                                )}
                            </div>
                        </div>
                        {/* <div className="col-lg-7 col-md-12">
                            <div className="otder d-flex flex-column align-items-lg-start align-items-md-center justify-content-center">
                                <h5>Self-Intro YouTube video link</h5>
                                <JobLink introVideoLink={introVideoLink} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default YouTubeVideoLike;