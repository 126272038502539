import React from 'react';
import { useState } from 'react';
import ViewJobsButtonComponent from "../ProfileView/ViewJobsButtonComponent";


const HelperProfile = ({ helperDetails }) => {


    const calculateAge = (dob) => {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age + ' Years';
    };


    return (
        <>
            <div className="help-inner d-flex justify-content-between">
                <div className="d-flex align-items-center dark">
                    <div className="help-1">
                        <img
                            src={
                                helperDetails?.registrationStep6Data?.profilePicURL
                                    ? helperDetails.registrationStep6Data.profilePicURL
                                    : "/demo-user.png"
                            }
                            alt="userimg"
                            onError={(e) => {
                                e.target.onerror = null; // Prevents infinite loop in case fallback image also fails
                                e.target.src = "/demo-user.png"; // Set fallback image
                            }}
                        />
                    </div>
                    <div className="help-content">
                        <h1>{helperDetails?.registrationStep2Data?.aboutYou?.fullName || ''}</h1>
                        <h5> Helper Doc I  {calculateAge(helperDetails?.registrationStep2Data?.aboutYou?.dob || '')} </h5>
                        <div className="normali d-flex justify-content-center align-items-center">
                            <h5 className="joby"> {helperDetails?.registrationStep4Data?.jobPreferences?.jobType || ''}</h5>
                            <p className="ops">Helper Doc I {helperDetails?.registrationStep2Data?.aboutYou?.currentEmploymentStatus || ''}</p>
                        </div>
                    </div>
                </div>
                <ViewJobsButtonComponent phoneNumber={helperDetails?.registrationStep2Data?.aboutYou?.whatsapp?.number || ''} />

            </div>
        </>
    );
};



export default HelperProfile;
