import React from 'react';
import PropTypes from 'prop-types';

const GeneralInformation = ({ profileDetail }) => {
    const qnaAnswer = profileDetail?.registrationStep5Data?.qna;
    return (
        <>  <div className="Expectations">
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#seven"
                        aria-expanded="true"
                        aria-controls="flush-collapseThree"
                    >
                        General Information
                    </button>
                </h2>
                <div
                    id="seven"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                >
                    <div className="col-12">
                        <div className="btsm">
                            <ul>
                                <li>
                                    Do you smoke? * <span className="nbvc">{qnaAnswer?.smoke ? 'Yes' : 'No'}</span>
                                </li>
                                <li>
                                    Do you drink alcohol? * <span className="nbvc">{qnaAnswer?.drinkAlcohol ? 'Yes' : 'No'}</span>
                                </li>
                                <li>
                                    Have you been vaccinated against the COVID-19 disease? *{" "}
                                    <span className="nbvc">{qnaAnswer?.vaccinatedCovid19 ? 'Yes' : 'No'}</span>
                                </li>
                                <li>
                                    Do you have tattoo? * <span className="nbvc">{qnaAnswer?.hasTattoo ? 'Yes' : 'No'}</span>
                                </li>
                                <li>
                                    Can you get along with dog, cats or other pets? *{" "}
                                    <span className="nbvc">{qnaAnswer?.comfortableWithPets ? 'Yes' : 'No'}</span>
                                </li>
                                <li>
                                    Are you comfortable to work with co-helper? *{" "}
                                    <span className="nbvc">{qnaAnswer?.comfortableWithCoHelper ? 'Yes' : 'No'}</span>
                                </li>
                                <li>
                                    Are you willing to take day off that is not on Sunday sometimes? *{" "}
                                    <span className="nbvc">{qnaAnswer?.willingToWorkOnNonSundayDayOff ? 'Yes' : 'No'}</span>
                                </li>
                                <li>
                                    Are you willing to accept monetary compensation to work on your
                                    holidays? * <span className="nbvc">{qnaAnswer?.willingToAcceptMonetaryCompensationOnHolidays ? 'Yes' : 'No'}</span>
                                </li>
                                <li>
                                    Do you accept to work in the employer's house equipped with camera
                                    recording system? <span className="nbvc">{qnaAnswer?.agreeToHouseCameraSurveillance ? 'Yes' : 'No'}</span>
                                </li>
                                <li>
                                    Are you willing to return home no later than the time set by your
                                    employer on your holiday? <span className="nbvc"> {qnaAnswer?.willingToReturnHomeByEmployerSetTime ? 'Yes' : 'No'}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>

    );
};



export default GeneralInformation;
