// src/components/OurServices/OurServices.tsx
import React, { useState, useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContentList from "../../Components/ContentList/ContentList";

const NewsFeed = () => {
    const [news, setNews] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const mockApiResponse = [
            { id: 1, title: 'Company Expansion', content: 'We are excited to announce our expansion into new markets.' },
            { id: 2, title: 'Product Launch', content: 'Introducing our latest product with innovative features.' },
            { id: 3, title: 'Achievements', content: 'Celebrating milestones and achievements in our journey.' },
            // Add more news articles as needed
        ];
        setNews(mockApiResponse);
    }, []);

    return (
        <section className="servicesSection" style={{ padding: '40px 0' }}>
            <Container>
                <Grid container justifyContent="center" textAlign="center">
                    <Grid item xs={12} className="findHelperTitle mb-5">
                        <Typography variant="h2" className="title" gutterBottom>
                            {t("Company News")}
                        </Typography>
                        <ContentList items={news} />
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
};

export default NewsFeed;
