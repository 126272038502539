import React from 'react';
import PropTypes from 'prop-types';
import FamilyMembersInfo from './FamilyMembersInfo';
import DutiesInfo from './DutiesInfo';
const WorkExperience = ({ workExperience }) => {


    const formatDate = (dateString) => {
        if (!dateString) return ''; // Handle null or undefined dates
        const options = { year: 'numeric', month: 'short' }; // 'short' for abbreviated month names
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    const experiences = workExperience?.registrationStep3Data;



    return (
        <>
            <div className="Work-experience">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#four"
                            aria-expanded="true"
                            aria-controls="collapseThree"
                        >
                            Work Experience
                        </button>
                    </h2>
                    <div
                        id="four"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <div className={`col-12 ${experiences?.length > 1 ? 'rubicon' : ''}`}>
                                {experiences && experiences.length > 0 ? (
                                    experiences.map((experience, index) => (
                                        <>
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div className="house pb-4 mb-4">
                                                        <h5 className="rubicon-p">
                                                            {experience?.duties?.[0] || ''}
                                                            {experience?.duties?.[1] ? `, ${experience.duties[1]}` : ''}
                                                        </h5>
                                                        <div className="d-flex align-items-center move">
                                                            <i className="fa-regular fa-calendar"></i>
                                                            {/* <span>Dec 2022 - Present</span> */}
                                                            <span>
                                                                {experience?.period?.start ? formatDate(experience.period.start) : ''}
                                                                {experience?.period?.start && experience?.period?.end ? ' - ' : ''}
                                                                {experience?.period?.end ? formatDate(experience.period.end) : ''}

                                                            </span>
                                                        </div>
                                                        <div className="d-flex add-h">
                                                            {/* <p className="add-1">Housekeeper</p> */}
                                                            <img src="/assets/images/mainlocation.svg" className="imgloc" />
                                                            <span className="add"> {experience?.location}</span>
                                                        </div>
                                                        <div className="sibv">

                                                            <FamilyMembersInfo familyMembers={experience.familyMembers} />
                                                            <p>
                                                                <b>House area -</b> {experience?.houseArea}
                                                            </p>
                                                            <p>
                                                                <b>Number of Co-helper -</b> {experience?.coHelperNumber}
                                                            </p>
                                                            <p>
                                                                <b>Employer Nationality -</b> {Array.isArray(experience?.employerNationality) && experience?.employerNationality.length > 0
                                                                    ? experience?.employerNationality.join(", ")
                                                                    : ""}

                                                            </p>
                                                            <DutiesInfo duties={experience?.duties} />
                                                            <p>
                                                                <b>I have a reference letter</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mt-5 d-flex align-items-start justify-content-center">
                                                    <div>
                                                        {/* <img src="/assets/images/profiledoc.svg" /> */}
                                                        <a href={experience?.references?.fileUrl} download target="_blank" >
                                                            <img
                                                                alt="Profile Doc"
                                                                src="/assets/images/profiledoc.svg"
                                                                style={{ cursor: 'pointer' }} // Ensure the image is responsive
                                                            />
                                                        </a>



                                                    </div>
                                                </div>
                                            </div>
                                        </>


                                    ))
                                ) : (
                                    <p>No work experience available.</p>
                                )

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};




export default WorkExperience;
