// src/components/OurServices/OurServices.tsx
import React, { useState, useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContentList from "../../Components/ContentList/ContentList";

const OurServices = () => {
    const [services, setServices] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const mockApiResponse = [
            {
                id: 1,
                title: "Web Development",
                content: "Creating awesome websites.",
            },
            {
                id: 2,
                title: "Mobile App Development",
                content: "Building cross-platform mobile apps.",
            },
            {
                id: 3,
                title: "Digital Marketing",
                content: "Promoting products through online channels.",
            },
        ];

        setServices(mockApiResponse);
    }, []);

    return (
        <section className="servicesSection" style={{ padding: '40px 0' }}>
            <Container>
                <Grid container justifyContent="center" textAlign="center">
                    <Grid item xs={12} className="findHelperTitle mb-5">
                        <Typography variant="h2" className="title" gutterBottom>
                            {t("Our Services")}
                        </Typography>
                        <ContentList items={services} />
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
};

export default OurServices;
